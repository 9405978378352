(function($) {

	AOS.init();
	
	/* Lightbox
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

// 	lightbox.option({
//     'resizeDuration': 200,
//     'wrapAround': true,
// 		'alwaysShowNavOnTouchDevices': true
//   })


	/* To the top
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	$(".back-to-top").click(function(){
      $("html, body").animate({ scrollTop: 0 }, 600);
      return false;
  });



	/* Swipe Menu (Custom Moblie Menu)
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	$('#mobile-menu').mmenu({
    extensions: ["border-full", "pagedim-black", "position-right"],
		navbar:
		{
			title	:	"Main menu"
		}
  });



	/* Sticky Header
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	// var headerHeight = $('.top-nav').outerHeight() + $('header').outerHeight();
	var headerHeight = $('header').outerHeight();

	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		headerHeight = $('header').outerHeight() + $('header').outerHeight();

		if(scroll > headerHeight){
			$('header').addClass('Sticky');
			$('body').addClass('menu_is_sticky');
		}else{
			$('header').removeClass('Sticky');
			$('body').removeClass('menu_is_sticky');
		}

		if(scroll > 700){
			$('.back-to-top').addClass('visible');
		}else{
			$('.back-to-top').removeClass('visible');
		}
	});


	/* Accordion
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	var allPanels = $('.accordion-block .item .answer').hide();
	var question = $('.accordion-block .item .question');

	$('.accordion-block .item .question a').click(function() {
		allPanels.slideUp();
		question.removeClass( "active" );
		$(this).parent().next().slideDown();
		$(this).parent().addClass( "active" );
		return false;
	});

})( jQuery );
